import styles from "./index.module.css";
import type { FC } from "react";

type Props = {
  cancelFeePercentage: number;
};
export const ReservationConfirmationNotes: FC<Props> = ({
  cancelFeePercentage,
}) => (
  <div className={styles.policy}>
    <div className={styles.policyTitle}>注意事項</div>
    <div className={styles.policyText}>
      ・リクエスト予約が承認されると、決済が自動的に処理されます。
      <br />
      ・利用するFanstaポイントは、リクエスト予約完了時に減算されます。ただし、リクエスト予約承認前にキャンセルされた場合、またはリクエスト予約が却下された場合には、5日以内にポイントが返還されます。
      <br />
      ・リクエスト予約承認前にキャンセルを行った場合、キャンセルポリシーの適用やキャンセル料の発生はありません。
      <br />
      ・リクエスト予約承認後にキャンセルを行った場合、キャンセルポリシーとは別にキャンセル手数料（返金金額の
      {cancelFeePercentage}%）が発生する場合があります。
      <br />
      ・キャンセル料やキャンセル手数料が発生した場合は、返金金額からそれらを差し引いた金額をご返金いたします。
      <br />
      ・来店日に来店されなかった場合、決済の返金は致しかねます。また、利用ポイントも返還されません。
      <br />
      ・決済処理は店舗からFanstaが委託され、Stripe,Inc.に再委託しています。
      <br />
      ・観戦予定の試合が中止になった場合は、直接お店へご連絡ください。試合中止の場合のご予約キャンセルでは、お支払い金額と利用ポイントを全額返金いたします。
    </div>
  </div>
);
